
.myNav, .myNav-mob{
    background-color: white;
    padding:5px 40px 5px 40px;
    box-shadow: 0px 1px 10px 0px;
    font-size: 16px;
}
.myNav a, .myNav-mob a{
    text-decoration: none;
    color:black;
    margin-left: 10px;
}
#toggle{
    background-color: #f2f2f2;
    margin-top: 1px;
    z-index: 10;
    position: fixed;
    width:100%;
}
#toggle ul{
    list-style-type: none;
    margin: 0px;
    padding: 15px;
    font-size: 14px;
}
#toggle li{
    margin-top: 8px;
}
#toggle li a{
    text-decoration: none;
    color: black;
}
.myNav-mob{
    display: none;
}
@media screen and (max-width: 600px) {
    .myNav{
        display: none;
    }
    .myNav-mob{
        display: block;
    }
}